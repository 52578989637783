<template>
  <div class="tag-cloud">
      <router-link :to="{ name: 'Themas', params: { thema: tag.thema }}">
        #{{tag.thema}}
      </router-link>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: ['tag'],
  setup(props) {
    const snippet = computed(() => {
      return props.tag.thema.substring(0, 3) + '...'
    })

    return { snippet }
  }

}
</script>

<style>
  .tag-cloud {
    padding: 10px;
  }
  .tag-cloud h3 {
    border-bottom: 1px solid #eee;
    padding: 16px 8px;
    color: #444;
  }
  .tag-cloud div {
    display: inline-block;
    padding: 10px;
  }
  .tag-cloud a {
    color: #ccc;
    text-decoration: none;
  }
  .tag-cloud a.router-link-active {
    color: #198754;
    font-weight: bold;
  }
</style>
