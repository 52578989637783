<template>
  <div class="post-list">
    <div v-for="post in posts" :key="post">
      <SinglePost :post="post" />
    </div>
  </div>
</template>

<script>
// les 78 
import { onMounted, onUnmounted, onUpdated } from 'vue'

// component imports
import SinglePost from './SinglePost.vue'

export default {
  props: ['posts'],
  components: { SinglePost },
  setup() {

  }
}
</script>

<style>

</style>