<template>
  <div class="post">
    <router-link :to="{ name: 'Details', params: { id: post.id }}">
      <h3>{{ post.title }}</h3>
    </router-link>
    <p><span v-html="snippet"></span></p>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: ['post'],
  setup(props) {
    // les 77 7min
    const snippet = computed(() => {
      return props.post.body.substring(0, 100) + '...'
    })

    return { snippet }
  }
}
</script>

<style>
  .post {
    margin: 0 20px 25px;
    padding-bottom: 20px;
    border-bottom: 2px dashed #e7e7e7;
  }
  .post h3 {
    display: block;
    position: relative;
    font-size: 26px;
    color: white;
    background: #198754;
    text-decoration: none;
    margin-bottom: 10px;
    padding: 6px;
    left: -30px;
    max-width: 400px;
    transform: rotateZ(-1deg);
  }
  .post h3::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    padding-right: 40px;
    left: -30px;
    transform: rotateZ(-1deg);
    z-index: 0;
  }
</style>
