import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Details from '../views/Details.vue'
import Themas from '../views/Themas.vue'
import Tagtest from '../views/Tagtest.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/posts/:id',
    name: 'Details',
    component: Details,
    props: true
  },
  {
    path: '/tags/:',
    name: 'Themas',
    component: Themas
  },
  {
    path: '/create',
    beforeEnter() {location.href = 'https://www.aavlaanderen.org'}
  },
  {
    path: '/tags/',
    name: 'Tagtest',
    component: Tagtest
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
