import { ref } from 'vue'
const axios = require('axios');
const getPost = (id) => {

  const post = ref(null)
  const error = ref(null)

  const load = async () => {
    try {
      const resp = await axios.get('https://www.5v12.be/blog/api/read-singleblog.php/?id=' + id);
      post.value = resp.data;
    } catch (err) {
      // Handle Error Here
      error.value = "Blog niet gevonden";
    }
  }

  return { post, error, load } 
}

export default getPost