<template>
  <div class="tag">
    <div v-if="error">{{ error }}</div>
    <div v-if="posts.length" class="layout">
      <PostList :posts="postsWithTag" />
      <TagList :tags="tags" />
    </div>
    <div v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
import getPosts from '../composables/getPosts'
import getTags from '../composables/getTags'
import PostList from '../components/PostList.vue'
import TagList from '../components/TagList.vue'
import Spinner from '../components/Spinner.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  components: { PostList, Spinner, TagList },
  setup() {
    const route = useRoute()
    const { posts, error, load } = getPosts()
    load()
    const { tags, loaden } = getTags()
    loaden()

    const postsWithTag = computed(() => {
      return posts.value.filter(p => p.tags.includes(route.params.thema))
    })

    return { error, posts, tags, postsWithTag }
  }
}
</script>

<style>
  .tag {
    max-width: 900px;
    margin: 0 auto;
    padding: 10px;
  }
</style>