import { ref } from 'vue'
const axios = require('axios');
const getTags = () => {

  const tags = ref([])


  const loaden = async () => {
    try {
        const resp = await axios.get('https://www.5v12.be/blog/api/read-tags.php');
        tags.value = resp.data.tags;
    } catch (err) {
        // Handle Error Here
    }
  }

  return { tags, loaden }
}

export default getTags
