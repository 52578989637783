<template>
  <div class="tags-list">
    <h3>Themas</h3>
    <div v-for="tag in tags" :key="tag">
      <SingleTag :tag="tag" />
    </div>
  </div>
</template>

<script>
// les 78 
import { onMounted, onUnmounted, onUpdated } from 'vue'

// component imports
import SingleTag from './SingleTag.vue'

export default {
  props: ['tags'],
  components: { SingleTag },
  setup() {

  }
}
</script>

<style>
  .tags-list {
    max-width: 100px;
    margin: 0 auto;
  }

</style>