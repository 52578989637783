<template>
  <div class="home">
    <div v-if="error">{{ error }}</div>
    <div v-if="posts.length" class="layout">
      <PostList :posts="posts" />
      <TagList :tags="tags" />
    </div>
    <div v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
// ref = les 72 - 73 -> computed les 75 voor zoeken in een array, ook watch en watchEffect les 76
// uitleg <PostList :posts="posts" /> les 77
import getPosts from '../composables/getPosts'
import getTags from '../composables/getTags'
import PostList from '../components/PostList.vue'
import TagList from '../components/TagList.vue'
import Spinner from '../components/Spinner.vue'

export default {
  name: 'Home',
  components: { PostList, Spinner, TagList },
  // setup wordt eerst uitgevoerd bij openen index.html = les 71
  setup() { 
    const { posts, error, load } = getPosts()
    load()
    const { tags, loaden } = getTags()
    loaden()
    // om iets zichtbaar te maken, altijd via return {}
    return { posts, tags, error }
  },
}
</script>

<style>
  .home {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
  }
  .layout {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 10px;
  }
</style>
