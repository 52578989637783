<template>
  <div v-if="posts && posts.length">
    <ul>
      <li v-for="post in posts" :key = "post">
        <p><strong>{{post.title}}</strong></p>
        <p>{{post.body}}</p>
      </li>
    </ul>
  </div>
  <div>
    <ul v-if="errors && errors.length">
      <li v-for="error in errors" :key = "error">
        {{error.message}}
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      posts: [],
      errors: []
    }
  },
  mounted: function () {
  	console.log("Vue.js is running...");
  	this.getAllUsers();
  },

  methods: {
    // Fetches posts when the component is created.
    getAllUsers: function () {
      axios.get(`https://www.5v12.be/blog/api/read-blogs.php`)
      .then(response => {
        // JSON responses are automatically parsed.
        this.posts = response.data.posts;
      })
      .catch(e => {
        this.errors.push(e)
      })
    }
  }
}
</script>

<style>
</style>