import { ref } from 'vue'
const axios = require('axios');
const getPosts = () => {

  const posts = ref([])
  const error = ref(null)

  const load = async () => {
    try {
        const resp = await axios.get('https://www.5v12.be/blog/api/read-blogs.php');
        posts.value = resp.data.posts;
    } catch (err) {
        // Handle Error Here
        error.value = "Blogs niet gevonden";
    }
  }

  return { posts, error, load }
}

export default getPosts